import * as React from "react"
import { Link } from "gatsby"
import { Waypoint } from 'react-waypoint'

import Layout from "../components/layout"
import Seo from "../components/seo"

import IconNext from "../images/icon-next.png"
import IconCallOutSpeechBubbles from "../images/icon-callout-speech-bubbles.svg"

import QuoteDesktop from "../images/quote-what-is-pmr-desktop.png"
import QuoteMobile from "../images/quote-what-is-pmr-mobile.png"
import LockUpHeads from "../images/lockup-heads-what-is-pmr.svg"
import LockUpFire from "../images/lockup-fire-what-is-pmr.svg"

import HexIconsOtherSymptomsDesktop from "../images/hex-icons-other-symptoms-desktop.png"
import HexIconsOtherSymptomsMobile from "../images/hex-icons-other-symptoms-mobile.png"
import HexIconsTasksDesktop from "../images/hex-icons-tasks-desktop.png"
import HexIconsTasksMobile from "../images/hex-icons-tasks-mobile.png"
import HexIconsConnectionBetweenDesktop from "../images/what-is-pmr-hep-group-icons-desktop.png"
import HexIconsConnectionBetweenMobile from "../images/hex-icons-connection-between-mobile.png"
import HepArrowsUpDesktop from "../images/what-is-pmr-hep-arrows-up-desktop.png"
import HepArrowsDownDesktop from "../images/what-is-pmr-hep-arrows-down-desktop.png"

import HeroImage from "../images/hero-what-is-pmr.png"

const WhatIsPmr = () => {

  const handleVerticalLineAnimations = () => {
    const target = document.querySelectorAll('.vertical-line-dark-red');
    target.forEach((element) => {
      element.classList.add('animate');
    });
  }

  const handleHepArrowAnimations = () => {
    const target = document.querySelectorAll('.ani-hep-icon-arrow');
    target.forEach((element) => {
      element.classList.add('animate');
    });    
  }

  return (
    <Layout>
      <Seo 
        title="What is PMR?"
        desc="Learn about the symptoms of PMR."
        ogtitle="What is PMR? | PMR and You Website"
        ogdesc="Learn about the symptoms of PMR."
      />

      <div className="main-hero what-is-pmr">
        <h1 className="headline-hero">What Is PMR?</h1>
      </div>

      <div className="content-container">
        <div className="row" id="hero-how-common">
          <div className="columns small-12 medium-12 large-6">
            <p className="headline-body">How common is PMR?</p>
            <p>Polymyalgia rheumatica, or PMR, is an inflammatory rheumatic disease. It's the <strong>second most common</strong> rheumatic disease after rheumatoid arthritis (RA).</p>
          </div>
          <div className="columns small-12 medium-12 large-6 text-center large-text-right align-self-bottom mb-2 md_mb-2 lg_mb-0" style={{ width: '100%' }}>
            <img src={HeroImage} alt="woman painting" id="what-is-pmr-hero-image" />
          </div>
        </div>
      </div>

      <div className="content-container pb-0 md_pb-4">  
        <div className="callout light-gray row mt-2 mb-2 md_mt-4 md_mb-4" style={{ maxWidth: '1070px', margin: '0 auto' }}>
          <div className="columns small-12 medium-12 large-3">
            <p className="color-dark-red font-weight-semi-bold">PMR incidence</p>
            <p className="font-weight-normal"><span className="font-weight-medium">~1 out of every 2,000 people</span> aged 50 years and older are diagnosed with PMR.</p>
          </div>
          <div className="columns small-12 medium-12 large-1 align-self-middle">
            <div className="vertical-line-dark-red h90px left show-for-large"></div>
            <div className="horizontal-line-dark-red hide-for-large mt-2 mb-3"></div>
          </div>
          <div className="columns small-12 medium-12 large-4">
            <p className="color-dark-red font-weight-semi-bold">PMR and gender</p>
            <p className="font-weight-normal">Women are affected <span className="font-weight-medium">2-3 times</span> more than men.</p>
          </div>
          <div className="columns small-12 medium-12 large-1 align-self-middle">
            <div className="vertical-line-dark-red h90px left show-for-large"></div>
            <div className="horizontal-line-dark-red hide-for-large mt-2 mb-3"></div>
          </div>
          <div className="columns small-12 medium-12 large-3">
            <p className="color-dark-red font-weight-semi-bold">PMR and age</p>
            <p className="font-weight-normal">People aged <span className="font-weight-medium">50 years or older</span> have a greater risk of developing PMR. </p>
          </div>
        </div>
  
        <Waypoint onEnter={handleVerticalLineAnimations}/>

        <div>
          <p className="headline-body">What are the symptoms of PMR?</p>
          <p>Pain and stiffness in the hips and shoulders on both sides of the body are the most common symptoms of PMR.</p>
          <p className="headline-subhead">Other symptoms of PMR may include:</p>

          <div className="row mt-2 md_mt-2 mb-2 md_mb-2">
            <div className="callout light-gray columns small-12 medium-12 large-6">
              <p className="text-center mb-0 md_mb-0">
                <img src={HexIconsOtherSymptomsDesktop} alt="Joint swelling, morning stiffness, weakness, loss of appetite, weight loss, mild fever" className="show-for-large" />
                <img src={HexIconsOtherSymptomsMobile} alt="Joint swelling, morning stiffness, weakness, loss of appetite, weight loss, mild fever" className="hide-for-large" />
              </p>
            </div>
            <div className="columns large-1 show-for-large">&nbsp;</div>
            <div className="columns small-12 medium-12 large-5 text-center large-text-right">
              <div className="md_mb-2 show-for-large"><img src={QuoteDesktop} alt="The pain was excruciating--I got to my primary care doctor as quickly as I could. --a real person with PMR" /></div>
            </div>
          </div>

          <p className="lg_mb-3">PMR pain can change from day to day. Some days you might feel fine. Other days you might feel surprised at what you <em>can’t</em> do with your body.  You may have first felt PMR pain out of nowhere one day, or it could have grown over time.</p>
          
          <div className="mt-1 mb-3 hide-for-large text-center"><img src={QuoteMobile} alt="The pain was excruciating--I got to my primary care doctor as quickly as I could. --a real person with PMR" /></div>

          <p className="headline-subhead">Living with PMR can be difficult</p>
          <p>There are many symptoms that can make daily living hard.</p>
          <p>PMR pain may disrupt your sleep. This can make you feel tired during the day. For many people, PMR pain and stiffness can feel worse in the morning.</p>
          <p><strong>PMR may make it feel impossible to do the tasks that you once found easy, such as:</strong></p>

          <div className="callout light-gray row mt-3 md_mt-3 mb-3 md_mb-3">
            <img src={HexIconsTasksDesktop} alt="Rolling over in bed, getting out of bed, walking, getting up from the toilet, taking a shower, brushing your hair, brushing your teeth, getting in and out of a car" className="show-for-large" style={{ margin: '0 auto' }} />
            <img src={HexIconsTasksMobile} alt="Rolling over in bed, getting out of bed, walking, getting up from the toilet, taking a shower, brushing your hair, brushing your teeth, getting in and out of a car" className="hide-for-large" style={{ margin: '0 auto' }} />
          </div>
        </div>

        <div className="row mb-4 md_mb-4">
          <div className="columns small-12 medium-12 large-7">
            <p className="headline-subhead">PMR may also impact your mood</p>
            <p><strong>Nearly 1 out of 3 people with PMR</strong> have reported having depression.</p>
            <p>It’s important to talk to your doctor about how you’re feeling. <strong>You are not alone, and your doctor can provide resources and support to help you.</strong></p>
          </div>
          <div className="colums small-12 medium-12 large-5 text-center large-text-right">
            <img src={LockUpHeads} alt="people icon" style={{ width: '90%' }} />
          </div>
        </div>

        <div>
          <p className="headline-body">What is the connection between PMR and inflammation?</p>
          <p className="mt-4 md_mt-4 mb-4 md_mb-4 text-center" id="hep-icons-connection-between" style={{ position: 'relative' }}>
            <img src={HepArrowsUpDesktop} alt="icon arrow" className="show-for-large ani-hep-icon-arrow up one" />
            <img src={HepArrowsUpDesktop} alt="icon arrow" className="show-for-large ani-hep-icon-arrow up two" />
            <img src={HepArrowsUpDesktop} alt="icon arrow" className="show-for-large ani-hep-icon-arrow up three" />
            <img src={HepArrowsDownDesktop} alt="icon arrow" className="show-for-large ani-hep-icon-arrow down one" />
            <img src={HepArrowsDownDesktop} alt="icon arrow" className="show-for-large ani-hep-icon-arrow down two" />
            <img src={HexIconsConnectionBetweenDesktop} alt="Your immune system isn't working like it should" className="show-for-large" />
            <img src={HexIconsConnectionBetweenMobile} alt="Your immune system isn't working like it should" className="hide-for-large" />
          </p>
        </div>
        <Waypoint onEnter={handleHepArrowAnimations}/>
      </div>


      <div className="row">
        <div className="columns small-12 medium-12 large-4 mb-3">
          <img src={LockUpFire} alt="flame and shapes icon" />
        </div>
        <div className="columns small-12 medium-12 large-8" style={{ padding: '0 80px' }}>
          <div className="callout red"><p>PMR symptoms may go away with the help of steroids, other medications, or even on their own. If they come back, this is known as a flare.</p></div>
        </div>
      </div>


      <div className="content-container pb-4 md_pb-4">  
        <div className="callout red mt-4 md_mt-4 mb-4 md_mb-4" style={{ maxWidth: '822px', margin: '0 auto' }}>
          <p><img src={IconCallOutSpeechBubbles} alt="message bubbles icon" /></p>
          <p>Talk to your doctor and healthcare team</p>
          <p className="font-weight-normal">Together, you can set treatment goals to help you take your life back.</p>
        </div>

        <Link to="/pmr-treatment-journey/" className="callout-link">
          <div className="callout next-page what-is-pmr row" style={{ margin: '0 0 0 auto' }}>
            <div className="columns small-12 medium-8 large-9 align-self-middle mb-2 md_mb-0">Get to know the PMR treatment journey</div>
            <div className="columns small-12 medium-4 large-3 align-self-middle"><img src={IconNext} alt="arrow icon" className="icon-next-arrow icon-next-arrow-animation" /></div>
          </div>
        </Link>
      </div>
    </Layout>
  )
}
export default WhatIsPmr
